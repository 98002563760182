// Layout
$bp-sm: 520px;
$bp-md: 740px;
$bp-lg: 1000px;
$bp-xl: 1200px;

// Typography
$default-font-stack: 'Clash Grotesk Variable', sans-serif;
$font-thin: 200;
$font-light: 300;
$font-regular: 400;
$font-bold: 600;
$font-black: 700;

// Colors
$black: #191B18;
$background-color: #303030;
$white: #FFF;
$text-color: $white;
$gray: #838383;
$dark-gray: #757C72;
$light-gray: #D0D6C9;

// Light color scheme
$text-color-alt: #333;

// Brand colours
$light-green: #83E46C;
$green: #19AD3C;
$dark-green: #2E8915;

$cyan: #28E1CB;
$yellow: #E4E836;
$red: #E34E2D;
$purple: #C836E0;

// $blue:    #0d6efd;
// $indigo:  #6610f2;
// $purple:  #6f42c1;
// $pink:    #d63384;
// $red:     #dc3545;
// $orange:  #fd7e14;
// $yellow:  #ffc107;
// $green:   #28a745;
// $teal:    #20c997;
// $cyan:    #17a2b8;

$primary: $light-green;
$secondary: $green;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
// $light:         $gray-100;
// $dark:          $gray-800;

$theme-colors: () !default;
$theme-colors: map-merge((
	'primary': $primary,
	'secondary': $secondary,
	'success': $success,
	'info': $info,
	'warning': $warning,
	'danger': $danger,
	// 'light': $light,
	// 'dark': $dark
), $theme-colors);

$theme-colors: map-remove($theme-colors, 'light', 'dark');

// Buttons
$cta-background: $light-green;
$btn-padding-x: 2.5em;
$btn-padding-y: 0.25em;
$btn-font-size: 21px;
$btn-border-radius: 100px;

// Breakpoints
$bp-narrow: 40em;
$bp-wide: 64em;
$bp-x-wide: 84em;

// Bootstrap overwrites
$body-bg: $background-color;
$body-color: $text-color;
