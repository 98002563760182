// ==========================================================================
// Functions & useful shortcuts
// ==========================================================================

// Slightly lighten a color
@use "sass:math";

@function tint($color, $percentage) {
    @return mix(white, $color, $percentage);
}

// Slightly darken a color
@function shade($color, $percentage) {
    @return mix(black, $color, $percentage);
}

// em values
@function em($px, $font-size: 16px) {
    @return math.div($px, $font-size) * 1em;
}

// rem values
@function rem($px) {
    @return math.div($px, 16) * 1rem;
}

// ==========================================================================
// Helper mixins
// ==========================================================================

@mixin textGradient($gradient) {
    & {
        @supports (-webkit-background-clip: text) {
            background: $gradient;
            background-attachment: fixed;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transform: translate3d(0, 0, 0);
        }
	}
}

@mixin ir() {
    background-color: transparent;
    border: 0;
    display: inline-block;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
        width: 0;
        height: 150%;
    }
}

@mixin hidden {
    display: none !important;
    visibility: hidden;
}

@mixin visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    &.focusable:active,
    &.focusable:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}

@mixin invisible {
    visibility: hidden;
}
