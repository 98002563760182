@import '../../styles/tooling';

.grid {
    height: 100%;
    left: 0;
    opacity: 0.3;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;

    .line {
        border: 1px solid #000;
        position: absolute;
        display: none;

        @media screen and (min-width: $bp-lg) and (min-height: $bp-md)  {
            display: block;
        }

        @media (prefers-color-scheme: light) {
            border-color: #fff;
        }
    }

    .horizontal,
    .vertical {
        height: 100%;
        width: 100%;
    }

    .horizontal {
        .line {
            width: 100%;
            left: 0;
            top: 4vmin;
            height: 2px;

            @media screen and (min-width: $bp-lg) and (min-height: $bp-md)  {
                top: 10vmin;
            }

            &:nth-child(2) {
                top: 50vh;
            }

            &:nth-child(3) {
                bottom: 4vmin;
                top: auto;

                @media screen and (min-width: $bp-lg) and (min-height: $bp-md)  {
                    bottom: 10vmin;
                }
            }
        }
    }

    .vertical {
        .line {
            height: 100%;
            left: calc(50% - 1px);
            top: 0;
            width: 2px;

            &:nth-child(1) {
            display: none;

                @media screen and (min-width: $bp-lg) and (min-height: $bp-md)  {
                    display: block;
                }
            }

            &:nth-child(2) {
                left: 4vmin;

                @media screen and (min-width: $bp-lg) and (min-height: $bp-md)  {
                    left: 10vmin;
                }
            }

            &:nth-child(3) {
                left: auto;
                right: 4vmin;

                @media screen and (min-width: $bp-lg) and (min-height: $bp-md)  {
                    right: 10vmin;
                }
            }

            // &:nth-child(4) {
            //     transform: translateX(88vmin);
            // }

            // &:nth-child(5) {
            //     transform: translateX(174vmin);
            // }
        }

    }
}