@import '../../styles/tooling';

.container {
	padding: calc( 8vmin + env(safe-area-inset-top)) calc( 8vmin + env(safe-area-inset-right)) calc( 8vmin + env(safe-area-inset-bottom)) calc( 8vmin + env(safe-area-inset-left));
	height: var(--app-height);
	overflow-y: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;

	@media (min-width: $bp-sm) {
		height: auto;
	}

	@media screen and (min-width: $bp-lg) and (min-height: $bp-md)  {
		padding: 12vmin;
	}
}

.headlines {
	margin-bottom: 2vmin;

	@media screen and (min-width: $bp-lg) and (min-height: $bp-md)  {
		min-height: calc(50vh - 14vmin);
		width: calc(50vw - 14vmin);
		margin-bottom: 4vmin;
		max-width: 65em;
	}
}

.blurb {
	@media screen and (min-width: $bp-lg) and (min-height: $bp-md)  {
		min-height: calc(50vh - 14vmin);
		width: calc(50vw - 14vmin);
		max-width: 65em;
	}
}

.contact {
	@media screen and (min-width: $bp-lg) and (min-height: $bp-md)  {
		position: absolute;
		right: 12vmin;
		bottom: 12vmin;
		height: calc(50vh - 14vmin);
		width: calc(50vw - 14vmin);
	}
}

.jrg {
	display: flex;
	flex-direction: column;
	font-weight: 700;
	line-height: 1;
	overflow: visible;
	right: 4vw;
	top: 3vh;
	z-index: 1;
}

.word {
	display: flex;
	position: relative;
	left: -0.4vmin;
	width: auto;
}

.letter {
	align-content: center;
	display: flex;
	font-weight: bold;
	justify-content: center;
	text-align: center;
	text-transform: uppercase;
	transition: transform 0.3s ease, opacity 0.3s ease;
	width: 10vmin;

	@media screen and (min-width: $bp-lg) and (min-height: $bp-md)  {
		font-size: 6vmin;
		height: 6vmin;
		line-height: 1;
		width: 6vmin;
	}

	&:nth-child(1) {
		transition-delay: 0.05s;
	}

	&:nth-child(2) {
		transition-delay: 0.1s;
	}

	&:nth-child(3) {
		transition-delay: 0.15s;
	}

	&:nth-child(4) {
		transition-delay: 0.2s;
	}

	&:nth-child(5) {
		transition-delay: 0.25s;
	}

	&:nth-child(6) {
		transition-delay: 0.3s;
	}

	&:nth-child(7) {
		transition-delay: 0.35s;
	}

	.out & {
		opacity: 0;
		transform: translateY(-100%);

		&:nth-child(7) {
			transition-delay: 0.05s;
		}

		&:nth-child(6) {
			transition-delay: 0.1s;
		}

		&:nth-child(5) {
			transition-delay: 0.15s;
		}

		&:nth-child(4) {
			transition-delay: 0.2s;
		}

		&:nth-child(3) {
			transition-delay: 0.25s;
		}

		&:nth-child(2) {
			transition-delay: 0.3s;
		}

		&:nth-child(1) {
			transition-delay: 0.35s;
		}
	}
}

.social {
	display: flex;
	margin-top: 4.4vmin;

	@media screen and (min-width: $bp-lg) and (min-height: $bp-md)  {
		margin-top: 2.8vmin;
	}

	a {
		filter: sepia(0) saturate(0) hue-rotate(0) brightness(2000%);
		transition: transform 0.3s cubic-bezier(0.4,-0.4, 0.4, 1.5), filter 0.15s linear;

		@media (prefers-color-scheme: light) {
			filter: sepia(0) saturate(0) hue-rotate(0) brightness(100%);
		}

		&:hover {
			transform: scale(1.1);
			filter: sepia(100%) saturate(1200%) hue-rotate(46deg) brightness(87%);

			@media (prefers-color-scheme: light) {
				filter: sepia(100%) saturate(550%) hue-rotate(74deg) brightness(110%);
			}
		}
	}

	.socialLink img {
		height: 6vmin;
		margin-right: 20px;
		width: auto;

		@media screen and (min-width: $bp-lg) and (min-height: $bp-md)  {
			height: 2.2vmin;
		}
	}
}

@keyframes badgeRotation {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.badgeContainer {
	position: fixed;
	width: 256px;
	height: 256px;
	bottom: -128px;
	right: -128px;
	background: url('../../../public/img/badge.svg') no-repeat center;
	background-size: contain;
	z-index: 3;

	@supports (mask-image: url('')) {
		background: linear-gradient(45deg, $green 0%, $light-green 100%);
		background-size: contain;
		mask-image: url('../../../public/img/badge.svg');
		mask-size: contain;
		mask-repeat: no-repeat;
		mask-position: center;

		@media (prefers-color-scheme: light) {
			background: linear-gradient(45deg, $dark-green 0%, $green 100%);
		}
	}

	@media (min-width: $bp-sm) {
		height: 30vmax;
		width: 30vmax;
		top: calc(100% - 15vmax - 4vmin - 2px);
		left: calc(100% - 15vmax - 4vmin - 2px);
		bottom: auto;
		right: auto;
	}

	@media screen and (min-width: $bp-lg) and (min-height: $bp-md)  {
		height: 20vmax;
		width: 20vmax;
		top: calc(100% - 10vmax - 10vmin - 2px);
		left: calc(100% - 10vmax - 10vmin - 2px);
	}

	.badge {
		animation: badgeRotation 30s linear infinite;
		margin: 0;
		width: 100%;
	}
}