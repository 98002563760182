@import '../../styles/tooling';

div .btn,
div button {
	align-items: center;
	background: transparent;
	border-radius: 100px;
	border: 0.15em solid $white;
	color: $white;
	display: inline-flex;
	font-weight: 600;
	justify-content: center;
	letter-spacing: 0.2em;
	overflow: hidden;
	padding: 0.5em 2em 0.4em;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

	@media (prefers-color-scheme: light) {
		border-color: $black;
		color: $black;
	}

	@media screen and (min-width: $bp-lg) and (min-height: $bp-md)  {
		font-size: 2vmin;
	}

	@media screen and (min-width: 2020px) and (min-height: $bp-md)  {
		font-size: 2vmin;
	}

	&:hover {
		background-color: $white;
		color: $black;

		@media (prefers-color-scheme: light) {
			background-color: $black;
			color: $white;
		}
	}

	&.primary {
		padding-right: 3.5em;

		.arrow {
			content: '';
			display: inline-block;
			height: em(20, 26);
			margin-top: -0.1em;
			position: absolute;
			right: 1em;
			transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
			width: em(40, 26);
		}

		.arrow-1 {
			opacity: 1;
			transform: translate(0, 0);
		}

		.arrow-2 {
			opacity: 0;
			transform: translate(-150%, 0);
		}

		&:hover {
			.arrow-1 {
				opacity: 0;
				transform: translate(150%, 0);
			}

			.arrow-2 {
				opacity: 1;
				transform: translate(0, 0);
			}
		}
	}

	&.unstyled {
		border: 0;
		color: inherit;
		display: inline;
		font-size: inherit;
		line-height: inherit;
		padding: 0;
		vertical-align: baseline;

		&.link:hover {
			text-decoration: underline;
		}
	}
}
