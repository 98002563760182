// ==========================================================================
// Atmosphere
// ==========================================================================

html,
button,
input,
select,
textarea {
	color: $text-color;
	font-family: $default-font-stack;

	@media (prefers-color-scheme: light) {
		color: $text-color-alt;
	}
}

html {
	font-size: 1em;
}

body {
	background-color: #000;
	color: $text-color;
	font-size: 1em;
	position: relative;
	height: 100vh;
	overflow: hidden;

	@media (prefers-color-scheme: light) {
		background-color: #FFF;
		color: $text-color-alt;
	}

	@media (min-width: $bp-sm) {
		overflow: auto;
	}
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

// button:focus,
// input:focus,
// select:focus,
// textarea:focus,
// label:focus {
//     border-radius: 0.1px;
//     outline: none;
//     box-shadow: 0 0 0 2px rgab(currentColor, 0, 2);
// }

section {
	margin: 0 auto;
	padding: em(10, 16) em(20, 16);

	@media (min-width: $bp-wide) {
		padding: em(24, 16) em(20, 16);
		width: 50%;
	}
}

article {
	margin: 0 auto;
}

address {
	font-size: em(18, 16);
	font-style: normal;
	line-height: 1.555556;
}

hr {
	display: block;
	height: 0.5em;
	border: 0;
	margin: 5em 0;
	padding: 0;
	position: relative;

	&:before,
	&:after {
		border-top: 1px solid $light-gray;
		content: '';
		display: block;
		left: 50%;
		max-width: 100%;
		position: absolute;
		transform: translateX(-50%);
		width: 10em;
	}

	&:before {
		top: 0;
	}

	&:after {
		bottom: 0;
	}
}

hr.secondary {
	border-color: $light-gray;
}

audio,
canvas,
img,
video {
	display: block;
	height: auto;
	margin: 0;
	width: auto;
	max-width: 100%;
}

audio {
	width: 100%;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

textarea {
	margin-bottom: em(12, 16);
	resize: vertical;
}

::-moz-selection {
	background: tint($green, 50);
	text-shadow: none;
}

::selection {
	background: tint($green, 50);
	text-shadow: none;
}

// ==========================================================================
// Helper classes
// ==========================================================================

.sr-only {
	@include visuallyhidden;
}

// Tables
table {
	margin-bottom: 1em;
	width: 100%;

	tr {
		&:nth-child(odd) {
			background-color: #F6F8FA;
		}
	}

	th {
		border-left: 1px solid $gray;
		overflow: auto;
		padding: 0.25em 0.5em;
		text-align: left;

		&:first-of-type {
			border-left: 0;
		}
	}

	td {
		border-left: 1px solid $gray;
		padding: 0.25em 0.5em;

		&:first-of-type {
			border-left: 0;
		}
	}
}

// For wide tables
.table-container {
	overflow-x: auto;

	table {
		table-layout: initial;
		white-space: nowrap;
		width: initial;

		td,
		th {
			white-space: normal;
		}
	}
}

// Images
img,
span {
	max-width: 100%;
}
